/* Settings */ 

$site-width: 1440; /* (max site width) */

$browser-context: 16; /* (base font size) */
$line-height: 24;
$defaultFont: 'Roboto','Source Sans Pro','Poppins', sans-serif; /* (default font) */
$defaultFontWeight: 400; /* (default font wieght) */

$sv: 10px; /* (spacing variable) */

$minmargin: 20; /* (minimum page magin - left and right gap on mobile) */
$page-min-margin: 20px; /* (same as above, but with unit) */

$mobileBreakpoint: 767px;  /* (where the site goes mobile) */


// Flexbox Mixins
// http://philipwalton.github.io/solved-by-flexbox/
// https://github.com/philipwalton/solved-by-flexbox

@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}

%flexbox { @include flexbox; }

@mixin inline-flex {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

%inline-flex { @include inline-flex; }

@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}
	// Shorter version:
	@mixin flex-dir($args...) { @include flex-direction($args...); }


@mixin flex-wrap($value: nowrap) {
	// No Webkit Box fallback.
	-webkit-flex-wrap: $value;
	-moz-flex-wrap: $value;
	@if $value == nowrap {
		-ms-flex-wrap: none;
	} @else { 
		-ms-flex-wrap: $value; 
	}
	flex-wrap: $value;
}

@mixin flex-flow($values: (row nowrap)) {
	// No Webkit Box fallback.
	-webkit-flex-flow: $values;
	-moz-flex-flow: $values;
	-ms-flex-flow: $values;
	flex-flow: $values;
}

@mixin order($int: 0) {
	-webkit-box-ordinal-group: $int + 1;
	-webkit-order: $int;
	-moz-order: $int;
	-ms-flex-order: $int;
	order: $int;
}

@mixin flex-grow($int: 0) {
	-webkit-box-flex: $int;
	-webkit-flex-grow: $int;
	-moz-flex-grow: $int;
	-ms-flex-positive: $int;
	flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
	-webkit-flex-shrink: $int;
	-moz-flex-shrink: $int;
	-ms-flex-negative: $int;
	flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
	-webkit-flex-basis: $value;
	-moz-flex-basis: $value;
	-ms-flex-preferred-size: $value;
	flex-basis: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
    
	// Set a variable to be used by box-flex properties
	$fg-boxflex: $fg;

	// Box-Flex only supports a flex-grow value so let's grab the
	// first item in the list and just return that.
	@if type-of($fg) == 'list' {
		$fg-boxflex: nth($fg, 1);
	}
	
	-webkit-box-flex: $fg-boxflex;
	-webkit-flex: $fg $fs $fb;
	-moz-box-flex: $fg-boxflex;
	-moz-flex: $fg $fs $fb;
	-ms-flex: $fg $fs $fb;
	flex: $fg $fs $fb;	
    flex-basis: $fb;
	
}

@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value == space-around {
		-ms-flex-pack: distribute;		
	} @else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value;
}
	// Shorter version:
	@mixin flex-just($args...) { @include justify-content($args...); }
	@mixin jc($args...) { @include justify-content($args...); }


@mixin align-items($value: stretch) {
	@if $value == flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start;
	} @else if $value == flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $value;
		-ms-flex-align: $value;
	}
	-webkit-align-items: $value;
	-moz-align-items: $value;
	align-items: $value;
}
	// Shorter version:
	@mixin ai($args...) { @include align-items($args...); }


@mixin align-self($value: auto) {
	// No Webkit Box Fallback.
	-webkit-align-self: $value;
	-moz-align-self: $value;
	@if $value == flex-start {
		-ms-flex-item-align: start;
	} @else if $value == flex-end {
		-ms-flex-item-align: end;
	} @else {
		-ms-flex-item-align: $value;
	}
	align-self: $value;
}


@mixin align-content($value: stretch) {
	// No Webkit Box Fallback.
	-webkit-align-content: $value;
	-moz-align-content: $value;
	@if $value == flex-start {
		-ms-flex-line-pack: start;
	} @else if $value == flex-end {
		-ms-flex-line-pack: end;
	} @else {
		-ms-flex-line-pack: $value;
	}
	align-content: $value;
}

// #Mixins, custom shortcurts and functions

@mixin row_nw(){
	@include flexbox();
	@include flex-direction(row);
	@include flex-wrap(nowrap);
}

@mixin col_nw(){
	@include flexbox();
	@include flex-direction(column);
	@include flex-wrap(nowrap);
}

@mixin row_w(){
	@include flexbox();
	@include flex-direction(row);
	@include flex-wrap(wrap);
}

@mixin col_w(){
	@include flexbox();
	@include flex-direction(column);
	@include flex-wrap(wrap);
}


@mixin flex_c(){
	@include justify-content(center);
	@include align-items(center);
}

@mixin ie_w_fix($fb){
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		max-width: $fb;
	}
}

@mixin ie_p_fix($pt,$pb){
	@supports (-ms-ime-align:auto) {
		padding-top: $pt;
	    padding-bottom: $pb;
	}
}

// Other mixins

/* TEXT SHADOW */
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)) {
    text-shadow: $x $y $blur $color;
}

/* BOX SHADOW */
@mixin box-shadow(){
	box-shadow: 1px 2px 3px rgba(#222222, 0.3), 6px 6px 8px rgba(#222222, 0.1);
}

/* TRANSFORM  */
@mixin transform($params) {
    -webkit-transform: $params;
    -moz-transform:    $params;
    -ms-transform:     $params;
    transform:         $params;
}

/* TRANSFORM ORIGIN */
@mixin transform-origin($origin) {
 -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
     -ms-transform-origin: $origin;
      -o-transform-origin: $origin;
         transform-origin: $origin;
}

/* TRANSITION */
// @mixin transition($what: all, $length: 1s, $easing: ease-in-out) {
//     -moz-transition:    $what $length $easing;
//     -o-transition:      $what $length $easing;
//     -webkit-transition: $what $length $easing;
//     -ms-transition:     $what $length $easing;
//     transition:         $what $length $easing;
// }

/* calculate ems */
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

/* calculate rems */
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

/* calculate page margin */
$page-margin: calc((100vw - #{$site-width}px) / 2);
$page-margin-neg: calc((-100vw + #{$site-width}px) / 2);

/* responsive version of $sv (spacing variable) set above */
$sv-r: ($sv / ($site-width + ($page-min-margin * 2))) * 100vw;

/* spacing variable mixin - used for padding and margins, the second variable is a multiplier */
@mixin sv($prop, $x: 1){
	#{$prop}: #{$sv * $x};
	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
		#{$prop}: #{$sv-r * $x};
	}
}


@mixin sv-tb($prop, $x: 1){
	#{$prop}-top: #{$sv * $x};
	#{$prop}-bottom: #{$sv * $x};
	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
		#{$prop}-top: #{$sv-r * $x};
		#{$prop}-bottom: #{$sv-r * $x};
	}
}

@mixin sv-lr($prop, $x: 1){
	#{$prop}-left: #{$sv * $x};
	#{$prop}-right: #{$sv * $x};
	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
		#{$prop}-left: #{$sv-r * $x};
		#{$prop}-right: #{$sv-r * $x};
	}
}

@mixin sv-s($prop, $tb: 1, $lr: $tb, $b: $tb, $l: $lr){
	#{$prop}: #{$sv * $tb} #{$sv * $lr} #{$sv * $b} #{$sv * $l};
	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
		#{$prop}: #{$sv-r * $tb} #{$sv-r * $lr} #{$sv-r * $b} #{$sv-r * $l};
	}
}

/* calculate the view width based on a fixed value
 $prop : the property to set (if its hieght or width, max- will be set too
 $w : the width the calculate 
 $mw : the max with, ie the value to calculate the variable related to, defaults to the $site-width
*/
 
@mixin vw($prop, $w, $mw: 0, $important: null){
	$swppmm: ($site-width + ($minmargin * 2));
	@if ($mw != 0){
		$swppmm: $mw;
	}
	#{$prop}: #{($w / $swppmm) * 100}vw $important;
	@if (str-index($prop, 'width')) or (str-index($prop, 'height')){
		max-#{$prop}: #{$w}px $important;
	}
}

/* set a property to the value page margin - useful for blocks that arent rows but should sit inside it */
@mixin page-margin($prop){
	#{$prop}: $page-min-margin;
	@media screen and (min-width: #{$site-width + ($minmargin * 2)}px){
		#{$prop}: $page-margin;
	}
}

@function sv($m){
	@return #{$sv*$m};
}
@function svp($m){
	@return #{$sv-r*$m};
}

/* reset input areas to default styles */
@mixin appearance-none(){
	-ms-appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: transparent;
	border-radius: 0;
	border: 0;
	font-family: inherit;
	font-weight: inherit;
	font-size: inherit;
	&::-ms-expand {
	    display: none;
	}
}

/* accessible version of display: none; */
@mixin display-none(){
	display: block;
	height: 0;
	width: 0;
	overflow: hidden;
	margin: 0;
	border: 0;
	-ms-appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

@mixin bg($url, $pos: center center, $size: contain, $rep: no-repeat){
	background-image: url($url);
	background-position: $pos;
	background-size: $size;
	background-repeat: $rep;
}



// #Normalise
*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html{
	background-color: white;
	font-size: #{$browser-context}px;
	font-family: $defaultFont;
	font-weight: $defaultFontWeight;
    font-style: normal;
    line-height: #{$line-height / $browser-context};
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#wrapper{
	overflow: hidden;
	position: relative;
}

#page{
	width: 100%;
	overflow: hidden;
}

body{
	max-width: 100vw;
	overflow-x: hidden;
}


#Custom Utility Classes

.row{
	width: calc(100% - #{$minmargin * 2}px);
	max-width: #{$site-width}px;
	margin: auto;
	.row{
		width: 100%;
	}
	&.collapse{
		max-width: 1080px;
	}
	&.extra{
		max-width: 860px;
	}
	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
		width: calc(100% - #{$sv-r * 2});
		&.collapse{
			width: calc(100% - #{$sv-r * 8});
		}
	}
}

@mixin row{
	width: calc(100% - #{$minmargin * 2}px);
	max-width: #{$site-width}px;
	margin: auto;
	.row{
		width: 100%;
	}
	&.collapse{
		max-width: 1080px;
	}
	&.extra{
		max-width: 860px;
	}
	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint){
		width: calc(100% - #{$sv-r * 2});
		&.collapse{
			width: calc(100% - #{$sv-r * 8});
		}
	}
}

@mixin collapse{
	max-width: 1080px;
}

@mixin extra{
	max-width: 860px;
}

.rowbreaker{
	width: 100vw;
	margin-left: #{$sv-r * -1};
	@media screen and (min-width: #{$site-width + ($minmargin * 2)}px){
		margin-left: $page-margin-neg;
	}
	@media screen and (max-width: #{$site-width + ($minmargin * 2)}px){ // and (min-width:$mobileBreakpoint)
		padding-left: $page-min-margin;
		padding-right: $page-min-margin;
	}
	@media screen and (max-width:#{$mobileBreakpoint - 1}){
		margin-left: #{$page-min-margin * -1};;
	}
}

.display-none{
	@include display-none();
}

.appearance-none{
	@include appearance-none();
}

.display-mob, .mobile-only{
	display: none;
}

.row_nw{
	@include row_nw();
}

.row_w{
	@include row_w();
}

.col_nw{
	@include col_nw();
}

.col_w{
	@include col_nw();
}

.flex_c{
	@include flex_c();
}

.jc-sb{
	@include jc(space-between);
}

.jc-sa{
	@include jc(space-around);
}

.jc-se{
	@include jc(space-evenly);
}

.jc-c{
	@include jc(center);
}

.jc-fs{
	@include jc(flex-start);
}

.jc-fe{
	@include jc(flex-end);
}

.ai-c{
	@include ai(center);
}

.ai-fs{
	@include ai(flex-start);
}

.ai-fe{
	@include ai(flex-end);
}

@media screen and (max-width:#{$mobileBreakpoint - 1}){
	.display-mob{
		display: block;
	}
	.mobile-only{
		display: inline-block;
	}
	.desktop-only{
		display: none;
	}
}


pre{
	color: rgba(90, 19, 19, 1);
}

@mixin flexGap($gap, $direction:row){
	> *{
	 @if $direction = row{
	   margin: 0 $gap/2;
		 &:first-child{
		   margin-left: 0
		 }
		 &:last-child{
		   margin-right: 0
		 }    
	 }
	 @if $direction == column{
	   margin: $gap/2 0;
	   &:first-child{
		 margin-top: 0
	   }
		
	   &:last-child{
		 margin-bottom: 0
	   }
	 }
		 
	}
	 
   }

	