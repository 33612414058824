// homepage styles
#dialog {
  display: none;
  &.show {
    display: block;
  }
  position: fixed;
  z-index: 99999999;
  top: 0;
  overflow: auto;
  padding-top: 20%;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8);
  .dialog_box {
    background-color: $white;
    width: 40%;
    margin: auto;
    border-radius: 10px;

    border: 1px solid #888;
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      width: 80%;
    }
    @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
      width: 70%;
    }
    p {
      @include sv("padding", 6);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include sv("padding", 3);
      }
      font-size: 1.5rem;
      font-weight: 600;
    }
    .close {
      float: right;
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      cursor: pointer;
      @include sv("padding-right", 2);
      &:hover {
        color: #000;
      }
    }
  }
}
.homepage_content {
  min-height: 1024px;

  @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
    min-height: 1000px;
  }
  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
    min-height: 800px;
  }
  @include flexbox;
  @include flex-direction(column);
  @include jc(center);
  @media screen and (min-width: 768px) {
    @include flex-direction(row);
  }
  > div {
    @media screen and (min-width: 768px) {
      flex: 0 0 50%;
    }

    &.connections,
    &.stories {
      background-image: url(../images/story_background.png);
      background-size: cover;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;

      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        min-height: 600px;
        background-size: cover;

        background-repeat: no-repeat;
      }

      // background-color: $steel;
      #map_background {
        background-image: url(../images/map_bg.png);
        background-position: center;
        width: 100%;
        height: 100%;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          min-height: 600px;
        }
      }
      .contents {
        z-index: 99;
        @media screen and (min-width: 768px) {
          top: 30%;
        }
        position: absolute;
        top: 10%;
        width: 100%;
        text-align: center;
        color: $white;

        h1 {
          font-size: 6.875rem;
          line-height: 8rem;
          font-weight: 300;
          @include sv("margin-bottom", 3);
          @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
            font-size: 3.875rem;
            line-height: 4rem;
            font-weight: 150;
          }
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            font-size: 2.875rem;
            line-height: 2rem;
            font-weight: 200;
          }
        }
        > a {
          text-decoration: none;
          color: $white;
        }
        .click_section {
          @include sv("margin-bottom", 7.8);
          span {
            @include sv("margin-bottom", 0);
            cursor: pointer;
            color: $olive;
            font-size: 5rem;
            &:hover {
              &:before {
                display: inline-block;
                width: 75px;
                height: 75px;
                transform: rotate(-90deg);
                content: url(../images/arrow-white.svg);
              }
            }
            &.icon-arrow-white {
              display: inline-block;
              transform: rotate(-90deg);
              &:before {
                color: $olive;
              }
            }
            &.icon-arrow-up {
              &:before {
                color: $white;
              }
            }
          }
          .explore {
            @include sv("margin-top", 1.6);
            text-transform: uppercase;
          }
        }
        .borders {
          position: relative;
          margin-left: 25%;
          margin-right: 25%;
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            margin-left: 15%;
            margin-right: 15%;
          }
          @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
            margin-left: 5%;
            margin-right: 5%;
          }
          @include sv-s("padding", 0.3, 3.7);
          border-left: 3px solid $white;
          border-right: 3px solid $white;
          @include borders($white, 3px);
          .text {
            font-size: 1.125rem;
          }
        }
      }
    }
    &.stories {
      background-color: $red;
      .sliding_images {
        @include sv("padding-top", 12);
        @include flexbox;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        }
        @include flex-direction(column);
        flex-wrap: wrap;
        row-gap: 100px;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          row-gap: 60px;
        }
        .column {
          @include flexbox;
          flex-wrap: wrap;
          @include jc(space-between);

          .image_slider {
            img {
              width: 340px !important;
              height: 213px;
              opacity: 0.4;
              @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
