// selected_highlights styles
.highlights {
  @include sv("padding-top", 20);
  .highlights_categories {
    text-align: center;
    h1 {
      font-size: 6.875rem;
      line-height: 8rem;
      color: $orange;
      font-weight: 300;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        font-size: 3.5rem;
        line-height: 3rem;
        font-weight: 300;
      }
    }
  }
  .highlights_list {
    @include row_w;
    @include jc(center);

    a {
      text-decoration: none;
      color: $black;
      font-size: 1rem;
      line-height: 1.25rem;
      @include sv("margin-right", 1.6);
      @include sv("margin-bottom", 1.6);
      @include sv-s("padding", 1, 2);
      border: 1px solid $orange;
      border-radius: 50px;
      &:hover {
        background-color: $orange;
        color: $white;
      }
    }
  }
  .external_referrer {
    @include sv-s("margin", 2, 0, 6.4, 0);
    @include flexbox;
    @include jc(center);
    flex-wrap: wrap;
    text-align: center;
    a {
      text-decoration: none;
      color: $black;
      font-size: 1rem;
      line-height: 1.25rem;
      @include sv("margin-right", 1.6);
      @include sv("margin-bottom", 1.6);
      @include sv-s("padding", 1, 2);
      border: 1px solid $steel;
      border-radius: 50px;

      &:hover {
        background-color: $steel;
        color: $white;
      }
    }
  }
  .categories_with_names {
    @include flexbox;
    background-color: $white;
    @include sv-tb("padding", 12.8);
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      @include flex-direction(column);

      @include sv-s("padding", 6.8, 3, 8, 5);
    }
    @media screen and (max-width: 1100px) and (min-width: $mobileBreakpoint) {
      @include flex-direction(column);
      @include ai(center);

      @include sv-s("padding", 6.8, 14, 16, 14);
    }
    h1 {
      flex: 0 0 50%;
      padding-left: 10%;
      @include sv("margin-top", 0);
      font-size: 4.5rem;
      line-height: 4.5rem;
      color: $steel;
      font-weight: 300;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        flex: 0 0 100%;
        text-align: center;
        padding-left: 0px;
        font-size: 3rem;
        line-height: 2.5rem;
        @include sv(margin-bottom, 5.4);
        font-weight: 300;
      }
      @media screen and (max-width: 1100px) and (min-width: $mobileBreakpoint) {
        @include sv("padding-left", 0);
      }
    }
    .selected_highlights {
      .row {
        // @include flexbox;
        .data {
          p {
            color: $light-black;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 400;
            @include sv-tb("padding", 1.2);
            @include sv("margin-right", 3.2);
            border-bottom: 1px solid rgba(138, 124, 43, 0.3);
          }
        }
        max-width: 600px;

        > span {
          font-size: 2.5rem;
          cursor: pointer;
          &:before {
            color: $olive;
          }
          &.icon-chevron-left {
            position: absolute;
            bottom: -14%;
            left: 25%;
            z-index: 99;
            &:hover {
              display: inline-block;
              content: url(../images/arrow-left-filled.svg);
              width: 40px;
              height: 40px;
            }
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              position: absolute;
              bottom: -7%;
              left: 2%;
              z-index: 99;
            }
          }
          &.icon-chevron-right {
            position: absolute;
            bottom: -14%;
            right: 15%;
            z-index: 99;
            &:hover {
              display: inline-block;
              content: url(../images/arrow-left-filled.svg);
              transform: rotate(180deg);
              width: 40px;
              height: 40px;
            }
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              position: absolute;
              bottom: -7%;
              right: 2%;
              z-index: 99;
            }
          }
        }
        .slick-dots {
          position: absolute;
          bottom: -13%;
          left: 42%;
          @include flexbox;
          list-style: none;
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            bottom: -6%;
            left: 29%;
          }
          li {
            button {
              font-size: 0;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              border: none;
              margin: 5px;
              opacity: 0.5;
              background-color: $olive;
            }
            &.slick-active {
              button {
                opacity: 1;
              }
            }
          }
        }
        table {
          tbody tr td {
            width: 25%;
          }
        }
      }
    }
    &#research-fellows {
      .selected_highlights {
        .row {
          > span {
            &.icon-chevron-left {
              bottom: -14%;
              left: 25%;
              &:hover {
                display: inline-block;
                content: url(../images/arrow-left-filled.svg);
                width: 40px;
                height: 40px;
              }
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                bottom: -10%;
                left: 23%;
              }
            }
            &.icon-chevron-right {
              bottom: -14%;
              right: 32%;
              z-index: 99;
              &:hover {
                display: inline-block;
                content: url(../images/arrow-left-filled.svg);
                transform: rotate(180deg);
                width: 40px;
                height: 40px;
              }
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                bottom: -10%;
                right: 21%;
              }
              @media screen and (max-width: 769px) and (min-width: 450px) {
                right: 25%;
              }
            }
          }
          .slick-dots {
            position: absolute;
            bottom: -13%;
            left: 42%;
            @include flexbox;
            list-style: none;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              bottom: -9%;
              left: 41%;
            }
          }
        }
      }
    }
    &#research-students {
      .selected_highlights {
        .row {
          > span {
            &.icon-chevron-left {
              bottom: -14%;
              left: 25%;
              &:hover {
                display: inline-block;
                content: url(../images/arrow-left-filled.svg);
                width: 40px;
                height: 40px;
              }
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                bottom: -7%;
                left: 5%;
              }
              @media screen and (max-width: 769px) and (min-width: 450px) {
                bottom: -7%;
                left: 15%;
              }
            }
            &.icon-chevron-right {
              bottom: -14%;
              right: 19%;
              z-index: 99;
              &:hover {
                display: inline-block;
                content: url(../images/arrow-left-filled.svg);
                transform: rotate(180deg);
                width: 40px;
                height: 40px;
              }
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                bottom: -7%;
                right: 2%;
              }
              @media screen and (max-width: 767px) and (min-width: 450px) {
                right: 32%;
              }
            }
          }
          .slick-dots {
            position: absolute;
            bottom: -13%;
            left: 42%;
            @include flexbox;
            list-style: none;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              bottom: -6%;
              left: 29%;
            }
          }
        }
      }
    }
    &#summer-scholars {
      .selected_highlights {
        .row {
          > span {
            &.icon-chevron-left {
              bottom: -14%;
              left: 25%;
              &:hover {
                display: inline-block;
                content: url(../images/arrow-left-filled.svg);
                width: 40px;
                height: 40px;
              }
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                bottom: -11%;
                left: 4%;
              }
              @media screen and (max-width: 767px) and (min-width: 450px) {
                left: 25%;
              }
            }
            &.icon-chevron-right {
              bottom: -14%;
              right: 24%;
              z-index: 99;
              &:hover {
                display: inline-block;
                content: url(../images/arrow-left-filled.svg);
                transform: rotate(180deg);
                width: 40px;
                height: 40px;
              }
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                bottom: -11%;
                right: 23%;
              }
              @media screen and (max-width: 767px) and (min-width: 450px) {
                right: 25%;
              }
            }
          }
          .slick-dots {
            position: absolute;
            bottom: -13%;
            left: 42%;
            @include flexbox;
            list-style: none;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              bottom: -10%;
              left: 25%;
            }
            @media screen and (max-width: 767px) and (min-width: 450px) {
              left: 40%;
            }
          }
        }
      }
    }
    &#affiliates {
      .selected_highlights {
        .row {
          > span {
            &.icon-chevron-left {
              bottom: -11%;
              left: 12%;
              &:hover {
                display: inline-block;
                content: url(../images/arrow-left-filled.svg);
                width: 40px;
                height: 40px;
              }
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                bottom: -6%;
                left: -8%;
              }
              @media screen and (max-width: 767px) and (min-width: 450px) {
                left: 20%;
              }
            }
            &.icon-chevron-right {
              bottom: -11%;
              right: 21%;
              z-index: 99;
              &:hover {
                display: inline-block;
                content: url(../images/arrow-left-filled.svg);
                transform: rotate(180deg);
                width: 40px;
                height: 40px;
              }
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                bottom: -6%;
                right: -4%;
              }
              @media screen and (max-width: 767px) and (min-width: 450px) {
                right: 20%;
              }
            }
          }
          .slick-dots {
            position: absolute;
            bottom: -10%;
            left: 23%;
            @include flexbox;
            list-style: none;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              bottom: -5%;
              left: 7%;
            }
            @media screen and (max-width: 767px) and (min-width: 450px) {
              left: 35%;
            }
          }
        }
      }
    }
  }
}
.annual_report {
  .annual_container {
    @include flexbox;
    background-color: white;
    padding-top: 128px;
    padding-bottom: 128px;
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      @include flex-direction(column);

      @include sv-s("padding", 6.8, 3, 8, 5);
    }
    @media screen and (max-width: 1100px) and (min-width: $mobileBreakpoint) {
      @include flex-direction(column);
      @include ai(center);

      @include sv-s("padding", 6.8, 14, 16, 14);
    }
    h1 {
      flex: 0 0 50%;
      padding-left: 10%;
      margin-top: 0px;
      font-size: 4.5rem;
      line-height: 4.5rem;
      color: $steel;
      font-weight: 300;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        flex: 0 0 100%;
        text-align: center;
        padding-left: 0px;
        font-size: 3rem;
        line-height: 2.5rem;
        @include sv(margin-bottom, 5.4);
        font-weight: 300;
      }
      @media screen and (max-width: 1100px) and (min-width: $mobileBreakpoint) {
        @include sv("padding-left", 0);
      }
    }
    .annual_information {
      @include flexbox;
      .reports {
        a {
          display: block;
          color: $olive;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 400;
          @include sv-tb("padding", 1.2);
          @include sv("margin-right", 3.2);
          border-bottom: 1px solid rgba(138, 124, 43, 0.3);
          min-width: 300px;
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            min-width: 100%;
          }
        }
      }
    }
  }
}
.theses {
  width: 80%;
  margin: 0 auto;
  @include width(fullwidth);
  @include sv("padding-bottom", 16.8);
  @media screen and (max-width: 1700px) and (min-width: 1300px) {
    @include sv-lr("padding", 4);
  }
  .theses_title {
    @include sv-s("padding", 12.8, 0, 8, 0);
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 4.5rem;
    color: $steel;
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      font-size: 3rem;
      line-height: 2.5rem;
      text-align: center;
      font-weight: 300;
      @include sv-s("padding", 6.8, 0, 6, 0);
    }
  }
  .title {
    @include flexbox;
    @include jc(space-between);
    @include sv("padding-bottom", 5);
    border-bottom: 3px solid $olive;
    color: $light-black;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      width: 90vw;
      margin: 0 auto;
      overflow: scroll;
      > h3 {
        @include sv("padding-right", 2);
      }
    }
  }
  .theses_information {
    .data {
      .individual_list {
        @include flexbox;
        @include sv-tb("padding", 3.2);
        border-bottom: 1px solid $olive;
        p {
          color: $light-black;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 400;
        }
        .text_restrict {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 400;
          .hidden-text {
            display: none;
          }
          .more {
            @include flexbox;
            @include ai(center);
            @include icon(right, $icon-plus);
            @include sv("margin-top", 2);
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 600;
            color: $olive;
            cursor: pointer;
            &:after {
              @include sv("margin-left", 1);
              font-size: 1.25rem;
            }
          }
        }
        p a {
          text-decoration: none;
          text-transform: uppercase;
          color: $olive;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 600;
          @include flexbox;
          @include ai(center);
          span {
            font-size: 1.5rem;
            @include sv("margin-left", 0.8);
          }
        }
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          width: 90vw;
          margin: 0 auto;
          overflow: scroll;
          > p {
            @include sv("padding-right", 2);
          }
        }
      }
    }
    > span {
      font-size: 2.5rem;
      cursor: pointer;
      &:before {
        color: $olive;
      }
      &.icon-chevron-left {
        position: absolute;
        bottom: -14%;
        left: 33%;
        z-index: 99;
        &:hover {
          display: inline-block;
          content: url(../images/arrow-left-filled.svg);
          width: 40px;
          height: 40px;
        }
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          bottom: 0%;
          left: 6%;
        }

        @media screen and (max-width: 767px) and (min-width: 450px) {
          left: 20%;
        }
      }
      &.icon-chevron-right {
        position: absolute;
        bottom: -14%;
        right: 40%;
        z-index: 99;
        &:hover {
          display: inline-block;
          content: url(../images/arrow-left-filled.svg);
          transform: rotate(180deg);
          width: 40px;
          height: 40px;
        }
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          bottom: 0%;
          right: 6%;
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          right: 30%;
        }
        @media screen and (max-width: 1482px) and (min-width: 900px) {
          right: 37%;
        }
        @media screen and (max-width: 767px) and (min-width: 450px) {
          right: 20%;
        }
      }
    }
    .slick-dots {
      position: absolute;
      bottom: -13%;
      left: 41%;
      @include flexbox;
      list-style: none;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        bottom: 0.3%;
        left: 30%;
      }
      @media screen and (max-width: 767px) and (min-width: 450px) {
        left: 40%;
      }
      li {
        button {
          font-size: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: none;
          margin: 5px;
          opacity: 0.5;
          background-color: $olive;
        }
        &.slick-active {
          button {
            opacity: 1;
          }
        }
      }
    }
  }
  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
    @include sv("padding-bottom", 4);
  }
}
.grants {
  background-color: $white;

  .grants_container {
    @include sv("padding-bottom", 18.8);
    width: 80%;
    margin: 0 auto;
    @include width(fullwidth);
    @media screen and (max-width: 1700px) and (min-width: 1300px) {
      @include sv-lr("padding", 4);
    }
    .grants_title {
      @include sv-s("padding", 12.8, 0, 8, 0);
      font-size: 4.5rem;
      font-weight: 300;
      line-height: 4.5rem;
      color: $steel;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        font-size: 3rem;
        line-height: 2.5rem;
        text-align: center;
        font-weight: 300;
      }
    }
    .title {
      @include flexbox;
      @include jc(space-between);
      @include sv("padding-bottom", 5);
      border-bottom: 3px solid $olive;
      color: $light-black;
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        width: 90vw;
        margin: 0 auto;
        overflow: scroll;
        > h3 {
          @include sv("padding-right", 2);
        }
      }
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
        > h3 {
          @include sv("padding-right", 3);
        }
      }
    }
    .grants_information {
      .data {
        .individual_list {
          @include flexbox;
          @include sv-tb("padding", 3.2);
          border-bottom: 1px solid $olive;
          p {
            color: $light-black;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 400;
          }
          .text_restrict {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 400;
            .hidden-text {
              display: none;
            }
            .more {
              @include flexbox;
              @include ai(center);
              @include icon(right, $icon-plus);
              @include sv("margin-top", 2);
              font-size: 1rem;
              line-height: 1.5rem;
              font-weight: 600;
              color: $olive;
              cursor: pointer;
              &:after {
                @include sv("margin-left", 1);
                font-size: 1.25rem;
              }
            }
          }
          p a {
            text-decoration: none;
            text-transform: uppercase;
            color: $olive;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 600;
            @include flexbox;
            @include ai(center);
            span {
              font-size: 1.5rem;
              @include sv("margin-left", 0.8);
            }
          }
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            width: 90vw;
            margin: 0 auto;
            overflow: scroll;
            > p {
              @include sv("padding-right", 2);
            }
          }
        }
      }
      > span {
        font-size: 2.5rem;
        cursor: pointer;
        &:before {
          color: $olive;
        }
        &.icon-chevron-left {
          position: absolute;
          bottom: -14%;
          left: 33%;
          z-index: 99;
          &:hover {
            display: inline-block;
            content: url(../images/arrow-left-filled.svg);
            width: 40px;
            height: 40px;
          }
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            bottom: -1%;
            left: 6%;
          }
          @media screen and (max-width: 767px) and (min-width: 450px) {
            left: 20%;
          }
          @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
            bottom: -7%;
          }
        }
        &.icon-chevron-right {
          position: absolute;
          bottom: -14%;
          right: 38%;
          z-index: 99;
          &:hover {
            display: inline-block;
            content: url(../images/arrow-left-filled.svg);
            transform: rotate(180deg);
            width: 40px;
            height: 40px;
          }
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            bottom: -1%;
            right: 6%;
          }
          @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
            right: 25%;
          }
          @media screen and (max-width: 767px) and (min-width: 450px) {
            right: 35%;
          }
          @media screen and (max-width: 1482px) and (min-width: 900px) {
            right: 37%;
          }
          @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
            bottom: -7%;
          }
        }
      }
      .slick-dots {
        position: absolute;
        bottom: -13%;
        left: 41%;
        @include flexbox;
        list-style: none;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          bottom: -0.8%;
          left: 21%;
        }
        @media screen and (max-width: 767px) and (min-width: 450px) {
          left: 35%;
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          bottom: -6.5%;
        }
        li {
          button {
            font-size: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: none;
            margin: 5px;
            opacity: 0.5;
            background-color: $olive;
          }
          &.slick-active {
            button {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.back_to_top {
  background-color: white;

  @include sv-tb("padding", 8);
  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
    display: none;
  }
  .change_position {
    a {
      @include flexbox;
      @include jc(center);
      @include ai(center);
      flex-direction: column;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      color: $olive;
      @include sv("margin-top", 1.6);
      text-transform: uppercase;
      text-decoration: none;
      span {
        font-size: 4rem;
        color: $olive;
        @include sv("margin-top", 1.6);
      }
    }
    &.positioning {
      position: fixed;
      bottom: 100px;
      right: 80px;
    }
  }
}
