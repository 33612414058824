// stories_template styles
.story_container {
  @include sv("padding-top", 12);
  @include width(sitewidth);
  .title_section {
    @include sv("margin-bottom", 6);
    text-align: center;
    h1 {
      font-size: 6rem;
      font-weight: 300;
      font-family: "Source Sans Pro";
      color: $red;
      @include sv-s("margin", 0, 0, 6, 0);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        font-size: 3.5rem;
        line-height: 3rem;
        font-weight: 300;
      }
    }
    .borders {
      position: relative;
      @include borders($olive, 3px);
      @include sv-s("padding", 0.9, 3.7);
      border-left: 3px solid $olive;
      border-right: 3px solid $olive;
      margin-left: 35%;
      margin-right: 35%;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        margin-left: 15%;
        margin-right: 15%;
      }
      .text {
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          font-size: 1.125rem;
          line-height: 1.75rem;
          font-weight: 400;
        }
      }
    }
    .topic_image {
      @include width(fullwidth);
      @include sv-tb("margin", 12);
      @include flexbox;
      @include flex-direction(row-reverse);
      img {
        max-width: 760px;
        max-height: 495px;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          width: 100%;
        }
      }
    }
    .text {
      font-size: 1.5rem;
      line-height: 2.125rem;
      font-weight: 400;
    }
  }
  .feature_introduction {
    @include width(fullwidth);
    @media screen and (min-width: 1140px) and (max-width: 1640px) {
      @include sv-lr("padding", 4);
    }
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      @include sv-lr("padding", 2);
    }
    @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
      @include sv-lr("padding", 4);
    }
    h2 {
      font-family: "Source Sans Pro";
      font-size: 4.5rem;
      font-weight: 300;
      color: $red;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        font-size: 3rem;
        text-align: center;
      }
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
        @include sv-lr("padding", 2);
      }
    }
    .introduction_container {
      @include sv-s("margin", 0, 0, 16, 0);
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
        @include sv-lr("padding", 2);
      }
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include sv-lr("padding", 2);
        @include sv-tb("margin", 8);
      }
      .img_background {
        position: relative;

        .first_block {
          width: 70%;
          // min-height: 600px;
          @include sv("padding-top", 5);
          @include flexbox;
          @include flex-direction(column);
          @include jc(center);
          p {
            font-size: 1.5rem;
            line-height: 2.125rem;
            color: $red;
            @include sv("margin-bottom", 2);
          }
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            width: 100%;
          }
          @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
            width: 85%;
          }
          a {
            color: $olive;
          }
        }
        img {
          position: absolute;
          right: 0%;
          top: 0%;
          pointer-events: none;
        }
      }
      .text_blocks {
        display: none;
        @include sv("margin-top", 3);
        @include flexbox;
        @include flex-direction(column);
        @include jc(center);
        @include ai(flex-start);
        p {
          font-size: 1.5rem;
          line-height: 2.125rem;
          color: $red;
          @include sv("margin-bottom", 2);
          &.extra_spacing {
            @include sv-tb("padding", 3);
          }
        }
        a {
          color: $olive;
        }
        h4 {
          font-family: "Source Sans Pro";
          font-size: 3rem;
          font-weight: 300;
          color: $red;
          @include sv("margin-bottom", 2);
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            font-size: 1.5rem;
            text-align: center;
          }
        }
        table {
          width: 80%;
          font-size: 1.25rem;
          line-height: 1.875rem;
          font-weight: 600;
          color: $red;
          border-collapse: separate;
          border-spacing: 32px 20px;
          tr {
            td {
              border-bottom: 1px solid $olive;
              @include sv-tb("padding", 1);
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                width: 100vh;
              }
              a {
                color: $olive;
              }
            }
          }
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            width: 100%;
          }
        }
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          width: 100% !important;
        }
      }
    }
  }
  .text_blocks {
    @include sv("margin-top", 3);
    @include sv("margin-bottom", 15);
    @include flexbox;
    @include flex-direction(column);
    @include jc(center);
    @include ai(flex-start);
    &.building_blocks {
      @include ai(center);
    }
    p {
      font-size: 1.5rem;
      line-height: 2.125rem;
      color: $red;
      @include sv("margin-bottom", 2);
      &.extra_spacing {
        @include sv-tb("padding", 3);
      }
    }
    a {
      color: $olive;
    }
    h4 {
      font-family: "Source Sans Pro";
      font-size: 3rem;
      font-weight: 300;
      color: $red;
      @include sv("margin-bottom", 2);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        font-size: 1.5rem;
        text-align: center;
      }
    }

    table {
      width: 80%;
      font-size: 1.25rem;
      line-height: 1.875rem;
      font-weight: 600;
      color: $red;
      border-collapse: separate;
      border-spacing: 32px 20px;
      tr {
        td {
          width: 50%;
          border-bottom: 1px solid $olive;
          @include sv-tb("padding", 1);
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            width: 100vh;
          }
          a {
            color: $olive;
          }
        }
      }
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        width: 100%;
      }
    }

    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      width: 100% !important;
    }
  }
  .text_containers {
    @include sv("padding-bottom", 8);
    @include width(fullwidth);
    @media screen and (min-width: 1140px) and (max-width: 1640px) {
      @include sv-lr("padding", 4);
    }
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      @include sv-lr("padding", 2);
    }
    @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
      @include sv-lr("padding", 4);
    }
    h4 {
      color: $red;
      font-size: 4.5rem;
      font-weight: 300;
      @include sv("margin-bottom", 3);
    }
    p {
      font-size: 1.5rem;
      line-height: 2.125rem;
      color: $red;
      @include sv("margin-bottom", 2);
    }
    a {
      color: $olive;
    }
    table {
      width: 80%;
      font-size: 1.25rem;
      line-height: 1.875rem;
      font-weight: 600;
      color: $red;
      border-collapse: separate;
      border-spacing: 32px 20px;
      tr {
        td {
          border-bottom: 1px solid $olive;
          @include sv-tb("padding", 1);
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            width: 100%;
            display: inline-block;
          }
          a {
            color: $olive;
          }
        }
      }
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        width: 100%;
      }
    }
  }
  .profile_wrapper {
    .section_title {
      @include width(fullwidth);
      @include sv("margin-bottom", 8);
      font-weight: 300;
      font-size: 4.5rem;
      line-height: 4.5rem;
      color: $red;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include sv-lr("padding", 2);
        font-size: 3rem;
        line-height: 3rem;
      }
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
        @include sv-lr("padding", 6);
      }
    }
    .section_blocks {
      max-width: 869px;
      margin: 0 auto;
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
        @include sv-lr("padding", 2);
      }
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include sv-lr("padding", 2);
      }
      .icon-arrow-left {
        position: absolute;
        top: 50%;
        left: -10%;
        z-index: 99;
        cursor: pointer;
        font-size: 3rem;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          bottom: -1%;
          left: 9%;
          top: 95%;
        }
        @media screen and (max-width: 767px) and (min-width: 450px) {
          left: 9%;
          top: 95%;
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          left: 9%;
          top: 91%;
        }
        &:hover {
          &:before {
            display: inline-block;
            width: 48px;
            height: 48px;
            transform: rotate(-90deg);
            content: url(../images/arrow-up-filled.svg);
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              width: 3rem;
              height: 3rem;
              @include sv("margin-right", 2);
            }
            @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
              width: 3rem;
              height: 3rem;
              @include sv("margin-right", 2);
            }
          }
        }
      }
      .icon-arrow-right {
        position: absolute;
        top: 50%;
        right: -10%;
        z-index: 99;
        cursor: pointer;
        font-size: 3rem;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          bottom: -1%;
          right: 9%;
          top: 95%;
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          right: 9%;
          top: 91%;
        }
        &:hover {
          &:before {
            display: inline-block;
            transform: rotate(90deg);
            content: url(../images/arrow-up-filled.svg);
            width: 48px;
            height: 48px;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              min-width: 48px;
              min-height: 48px;
              //@include sv("margin-right", 2);
            }
            @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
              width: 3rem;
              height: 3rem;
              //@include sv("margin-right", 2);
            }
          }
        }
      }
      .slick-dots {
        position: absolute;
        cursor: pointer;
        bottom: 6%;
        left: 32%;
        width: 40%;
        @include flexbox;
        flex-wrap: wrap;
        list-style: none;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          bottom: 1%;
          left: 30%;
        }

        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          bottom: 5%;
          left: 29%;
        }
        li {
          button {
            font-size: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: none;
            margin: 5px;
            opacity: 0.5;
            background-color: $olive;
          }
          &.slick-active {
            button {
              opacity: 1;
            }
          }
        }
      }
      .personal_wrapper {
        @include sv-tb("margin", 10);
        .information_section {
          @include flexbox;
          flex: 1 1;
          .profile_section {
            flex: 60%;
            @include flexbox;
            @include flex-direction(column);
            @include jc(space-around);
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              flex: 50%;
            }
            .name {
              font-weight: 600;
              font-size: 3rem;
              line-height: 3.625rem;
              color: $red;
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                font-size: 2rem;
                line-height: 2.625rem;
              }
            }
            .link_section {
              @include flexbox;
              @include sv("margin-top", 1.6);

              > div {
                &.youtube {
                  a {
                    @include sv-s("padding", 1.35, 1.2);
                    .icon-youtube {
                      &::before {
                        color: $olive;
                      }
                    }
                  }
                }

                a {
                  text-decoration: none;
                  @include sv("margin-right", 1.2);
                  border-radius: 50%;
                  border: 1px solid $olive;
                  @include sv-s("padding", 1.2);
                  @include flexbox;
                  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                    margin-right: 0px;
                  }
                  span {
                    font-size: 1rem;
                    &.icon-youtube {
                      font-size: 0.8rem;
                    }
                  }
                }
              }
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                flex-wrap: wrap;
                gap: 10px;
              }
            }
            .position {
              font-size: 1.5rem;
              line-height: 2.125rem;
              font-weight: 400;
              color: $red;
            }
          }
          .image_section {
            flex: 40%;
            max-width: 420px;
            height: 380px;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              height: auto;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                @include sv("margin-top", 2);
                object-fit: contain;
                max-height: 300px;
              }
            }
          }
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            flex-direction: column;
          }
        }
        .bio {
          h4 {
            font-size: 1.25rem;
            line-height: 1.875rem;
            font-weight: 600;
            color: $red;
            @include sv("margin-bottom", 2);
          }
          p {
            line-height: 1.5rem;
            font-weight: 400;
            a {
              color: $olive;
            }
          }
          @include sv("margin-top", 7);
        }
      }
    }
  }

  .pagination_text {
    @include width(fullwidth);
    @include sv-lr("padding", 4);
    @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
      @include sv-lr("padding", 4);
    }
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      @include sv-lr("padding", 4);
    }
    .title {
      font-family: "Source Sans Pro";
      font-size: 4.5rem;
      font-weight: 300;
      color: #760e1f;
      @include sv("margin-bottom", 3);
      @include sv("margin-top", 16);
      @media screen and (max-width:#{$mobileBreakpoint + 1}) {
        font-size: 3rem;
        @include align-items(flex-start);
      }
    }
    .text_container {
      font-size: 1.5rem;
      line-height: 2.125rem;
      color: #760e1f;
      margin-bottom: 150px;
      .icon-arrow-left {
        position: absolute;
        top: 30%;
        left: -6%;
        z-index: 99;
        cursor: pointer;
        font-size: 3rem;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          bottom: -1%;
          left: 6%;
          top: 102%;
        }
        @media screen and (max-width: 767px) and (min-width: 450px) {
          left: 20%;
          top: 102%;
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          left: 20%;
          top: 110%;
        }
        &:hover {
          &:before {
            display: inline-block;
            width: 48px;
            height: 48px;
            transform: rotate(-90deg);
            content: url(../images/arrow-up-filled.svg);
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              width: 3rem;
              height: 3rem;
              @include sv("margin-right", 2);
            }
            @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
              width: 3rem;
              height: 3rem;
              //@include sv("margin-right", 2);
            }
          }
        }
      }
      .icon-arrow-right {
        position: absolute;
        top: 30%;
        right: -6%;
        z-index: 99;
        cursor: pointer;
        font-size: 3rem;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          bottom: -1%;
          right: 6%;
          top: 102%;
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          right: 36%;
          top: 110%;
        }
        @media screen and (max-width: 767px) and (min-width: 450px) {
          right: 36%;
          top: 102%;
        }
        &:hover {
          &:before {
            display: inline-block;
            content: url(../images/arrow-up-filled.svg);
            transform: rotate(90deg);
            width: 48px;
            height: 48px;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              min-width: 48px;
              min-height: 48px;
              @include sv("margin-right", 2);
            }
            @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
              width: 48px;
              height: 48px;
              //@include sv("margin-right", 2);
            }
          }
        }
      }
      .content {
        @include flexbox;
        @include jc(center);
        p {
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            width: 100%;
          }
        }
        a {
          color: $olive;
        }
      }

      .slick-dots {
        position: absolute;
        bottom: -24%;
        left: 41%;
        @include flexbox;
        list-style: none;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          bottom: -5%;
          left: 30%;
        }
        @media screen and (max-width: 767px) and (min-width: 450px) {
          left: 35%;
        }
        li {
          button {
            font-size: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: none;
            margin: 5px;
            opacity: 0.5;
            background-color: $olive;
          }
          &.slick-active {
            button {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .image_overlaying_text {
    @include width(fullwidth);
    @include sv("margin-bottom", 32);
    @media screen and (min-width: 1140px) and (max-width: 1640px) {
      @include sv("padding-left", 4);
    }
    @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:#{$mobileBreakpoint + 1}) {
      @include sv("padding-left", 4);
    }
    .image_text_wrapper {
      @include flexbox;
      overflow: hidden;

      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include flex-direction(column-reverse);
      }
      @media screen and (max-width: 781px) {
        @include flex-direction(column-reverse);
      }
      .text_container {
        z-index: 9;
        max-height: 700px;
        div[class^="text_block"] {
          font-size: 1.125rem;
          line-height: 1.75rem;
          font-weight: 400;
          color: $red;
          background-color: $white;
          padding: 40px;
          p {
            width: 100%;
            @include sv("margin-bottom", 2);
            a {
              color: $olive;
            }
          }
          h4 {
            width: 100%;
            text-align: left;
            @include sv-tb("margin", 2);
          }
          .audio_container {
            @include flexbox;
            @include align-self(flex-start);
            @include sv-tb("margin", 3);
            @include flex-direction(column);
            @include flexGap(30px, column);
          }
          ul {
            @include sv-s("padding", 2, 0, 0, 2);
          }
          table {
            width: 100%;
            @include sv-tb("padding", 2);
            tr {
              td {
                border-bottom: 1px solid $red;
              }
            }
          }
        }
        .content {
          //visibility: hidden;
          //opacity: 0;
          // transform: matrix(0.7, 0, 0, 0.7, 0, 0);
          // opacity: 0;;
          //  transform: translateY(-50px);
          // transition: all 1s ease-out;
          a {
            color: $olive;
          }
          &.animate {
            transition: all 1s ease-out;
          }
          transition: transform 0.3s ease-out;
          margin-top: 300px;
          margin-bottom: 600px;
          min-width: 400px;
          width: 120%;
          @include flexbox;
          @include flex-direction(column);
          @include jc(center);
          @include ai(flex-end);
          &.title {
            @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
              font-size: 4.5rem;
              font-weight: 350;
            }
            font-size: 6rem;
            font-weight: 300;
            color: $red;
            @media screen and (max-width:#{$mobileBreakpoint + 1}) {
              font-size: 3rem;
              @include align-items(flex-start);
              @include sv-lr("padding", 3);
              @include sv-tb("margin", 4);
            }
          }
          @media screen and (max-width:#{$mobileBreakpoint + 1}) {
            @include sv-tb("margin", 10);
            width: 100%;
          }
        }
        @media screen and (max-width:#{$mobileBreakpoint + 1}) {
          max-height: fit-content;
        }
      }
      .image_container.animated {
        width: 1000px;
        height: 800px;

        will-change: opacity;
        opacity: 0.5;
        @media screen and (max-width:#{$mobileBreakpoint + 1}) {
          opacity: 1;
        }
        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 500px;
            @include sv-lr("padding", 3);
          }
          @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:#{$mobileBreakpoint + 1}) {
            object-fit: cover;
          }
        }
        &.brighten {
          opacity: 1;
        }

        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          max-width: 100%;
          max-height: 100%;
          height: 100%;
        }
        @media screen and (max-width: 900px) and (min-width: $mobileBreakpoint) {
          width: 500px;
          height: 600px;
        }
        @media screen and (max-width: 1400px) and (min-width: 900px) {
          width: 600px;
          height: 400px;
        }
      }
    }
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      @include sv("margin-bottom", 10);
    }
    @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
      max-width: 100vw;
    }
  }
  .conclusion {
    background-color: $red;
    @include sv-s("padding", 18, 16);

    @media screen and (min-width: 1140px) and (max-width: 1640px) {
      @include sv-lr("padding", 4);
    }
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      @include sv-s("padding", 7, 5);
    }
    .conclusion_container {
      background-image: url(../images/conclusion_bg.svg);
      background-size: 100% 100%;
      @media (min-width: 1920px) and (max-width: 2560px) {
        background-position: -30%;
      }
    }
    h2 {
      font-size: 4.5rem;
      font-weight: 300;
      color: $white;
      @include sv("margin-bottom", 5);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        font-size: 2.5rem;
        @include sv("margin-bottom", 3);
      }
    }
    .optional_image {
      @include sv("margin-top", 5);
      @include sv("padding-left", 11);
      @include flexbox;
      @include flex-direction(column);
      max-width: 1156px;

      width: 100%;
      p {
        @include sv-tb("margin", 4);
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 2.125rem;
        font-family: "Source Sans Pro", "Roboto";
        color: $white;
        display: inline-block;
      }
      img {
        max-width: 100%;
        height: auto;

        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          width: 100%;
          @include sv("padding", 0);
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
        }
      }
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include sv("padding", 0);
      }
    }
    .img_background {
      @include flexbox;
      @include ai(center);
      @include sv("padding-left", 11);

      background-repeat: none;
      .text_wrapper {
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 2.125rem;
        font-family: "Roboto", "Source Sans Pro";
        color: $white;
        display: inline-block;
        max-width: 778px;
        @include sv("padding-top", 5);
        @include sv("margin-bottom", 18);
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          @include sv("margin-bottom", 1);
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          @include sv("margin-bottom", 10);
        }
        p {
          a {
            color: $olive;
          }
          &.spacer {
            @include sv("margin-top", 3);
          }
        }
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          font-size: 1.3rem;
          font-weight: 300;
          line-height: 1.5rem;
        }
      }
      img {
        display: inline-block;
        margin-left: -200px;
        pointer-events: none;
        max-width: 500px;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          max-width: 100%;
          max-height: 100%;
          margin-left: -200px;
          margin-top: -120%;
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          width: 70%;
          margin-left: -300px;
        }
      }
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        max-width: 100%;
        max-height: 100%;
        @include sv("margin-top", 4);
        @include sv("padding-left", 0);
      }
    }
    .selected_output_container {
      &.top_margin {
        @include sv("margin-top", 8);
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          @include sv("margin-top", 4);
        }
      }
      h3 {
        @include sv("margin-bottom", 8);
        font-size: 3rem;
        line-height: 3.625rem;
        font-weight: 300;
        color: $white;
        font-family: "Source Sans Pro", "Roboto";
      }
      .output_list {
        display: block;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 400;
        @include ai(center);
        width: 75%;
        @include sv-lr("padding", 11);

        p {
          display: block;
          @include sv-tb("padding", 3.2);
          border-bottom: 1px solid $white;
          color: $white;
          a {
            color: $olive;
            overflow-wrap: anywhere;
          }
        }
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          @include sv-lr("padding", 0);
          width: 100%;
        }
      }
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include sv("margin-top", 7);
      }
    }
    .hero_image_collection {
      h3 {
        @include sv-tb("margin", 8);
        font-size: 3rem;
        line-height: 3.625rem;
        font-weight: 300;
        color: $white;
        font-family: "Source Sans Pro", "Roboto";
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          @include sv-tb("margin", 4);
        }
      }
      .caption_list {
        display: block;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 400;
        @include ai(center);
        width: 75%;
        @include sv-lr("padding", 11);
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          width: 100%;
        }
        p {
          display: block;
          @include sv-tb("padding", 3.2);
          border-bottom: 1px solid $white;
          color: $white;
          a {
            color: $olive;
          }
        }
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          @include sv-lr("padding", 0);
        }
      }
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include sv("margin-top", 7);
      }
    }
    &.no_bg {
      background-image: none;
    }
  }
  .related_stories {
    background-color: $black_coedl;
    .list_stories {
      a {
        text-decoration: none;
        &:hover {
          > div img {
            opacity: 0.3;
          }
        }
      }
      @include width(fullwidth);
      @include sv-tb("padding", 12);
      @include row_w;
      @include justify-content(center);

      .stories_link {
        @include sv("margin-right", 8);
        img {
          width: 520px;
          height: 300px;
          object-fit: cover;
          @include sv("margin-bottom", 3.2);
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            max-width: 100%;
            max-height: 100%;
            width: 400px;
            height: 200px;
          }
        }
        .story_name {
          color: $white;
          max-width: 480px;
          h3 {
            font-size: 2rem;
            font-weight: 600;
            line-height: 2.25rem;
            font-family: "Source Sans Pro", "Roboto";
            @include sv("margin-bottom", 2.4);
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              font-size: 1.5rem;
              font-weight: 600;
              line-height: 1.75rem;
            }
          }
          p {
            font-family: "Source Sans Pro", "Roboto";
            font-size: 1.5rem;
            line-height: 2.125rem;
            font-weight: 400;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              font-size: 1.2rem;
              font-weight: 400;
              line-height: 1.8rem;
            }
          }
        }
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          @include sv-s("margin", 3, 0, 3, 0);
          @include sv-lr("padding", 4);
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          @include sv("margin-bottom", 8);
        }
      }
    }

    .show_all_stories {
      a {
        text-decoration: none;

        @include flexbox;
        @include jc(center);
        @include ai(center);
        color: $white;
        @include sv("padding-bottom", 15);
        @include width(fullwidth);
        border-bottom: 1px solid $white;
        span {
          font-size: 3rem;

          &.icon-arrow-left {
            @include sv("margin-right", 3.2);
            &::before {
              font-size: 3rem;
              color: $white;
            }
          }
          &.icon-arrow-white {
            @include sv("margin-right", 3.2);
            display: inline-block;
            transform: rotate(-180deg);
          }
        }
        h4 {
          text-transform: uppercase;
          font-size: 1.5rem;
          line-height: 1.875rem;
        }
      }
    }
  }
  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
    @include sv("padding-top", 7);
  }
}
