// header styles
body {
   // max-width: 1440px;
   // margin: 0 auto;
   &.no-background {
      background-color: $white-secondary;
      .header {
         .nav {
            &.list {
               a {
                  color: $white;
               }
            }
            a {
               color: $black;
            }
         }
      }
   }
}
.header {
   width: 100%;
   z-index: 9999;
   @include width(sitewidth);
   img {
      position: absolute;
      top: 0px;
      @include sv-s("padding", 2, 5);
      background-color: $white;
      opacity: 1;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      z-index: 9999;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         width: 50%;
         height: 7%;
         @include sv-s("padding", 1, 2.7, 1, 1);
      }
   }
   .nav {
      z-index: 9999;
      position: absolute;
      top: 0px;
      right: 0px;
      @include flexbox;
      width: 35%;
      min-height: 92px;
      flex: 0 0 30%;
      @include jc(space-around);
      @include ai(center);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         display: none;
      }
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
         width: 50%;
      }
      a {
         text-transform: uppercase;
         text-decoration: none;
         font-weight: 600;
         line-height: 24px;
         color: $white;
         &.is-active {
            border-bottom: 2px solid $olive;
         }
         &:last-of-type {
            @include sv("margin-right", 3);
         }
         &:hover {
            border-bottom: 2px solid $olive;
         }
         // color: $white;
      }
   }
   .mobile-nav {
      display: none;
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         display: block;
      }
      span {
         z-index: 99999999;
         font-size: 1.6rem;
         position: absolute;
         top: 20px;
         right: 20px;
         &.icon-cross {
            font-size: 2rem;
         }
      }
      .mobile-only {
         position: absolute;
         z-index: 999999;
         background-color: $steel;
         // overflow:hidden;
         width: 100vw;
         height: 100vh;
         @include flexbox;
         @include flex-direction(column);
         @include jc(center);
         @include ai(center);
         a {
            cursor: pointer;
         }
         &.hidden {
            display: none;
         }
         a {
            color: $white;
            text-decoration: none;
            font-size: 2rem;
            @include sv("margin-bottom", 3);
         }
      }
   }
}
