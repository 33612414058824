@mixin borders($color, $height){

               span.border-left-top{
                  position: absolute;
                  top: 0%;
                  left: -0.5%;
                  width: 3%;
                  height: $height;
                  background-color: $color;
                  
               }
                 span.border-right-top{
                  position: absolute;
                  top: 0%;
                  right: -0.5%;
                  width: 3%;
                  height: $height;
                  background-color: $color;
                  
               }
                  span.border-left-bottom{
                  position: absolute;
                  bottom: 0%;
                  left: -0.5%;
                  width: 3%;
                  height: $height;
                  background-color: $color;
                  
               }
                    span.border-right-bottom{
                  position: absolute;
                  bottom: 0%;
                  right: -0.5%;
                  width: 3%;
                  height: $height;
                  background-color: $color;
                  
               }
            }
