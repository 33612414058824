// footer styles
.footer {
   background-color: $black;
   @include sv-s("padding", 8, 0, 14, 0);
   text-align: center;
   .footer_text {
      @include sv("margin-bottom", 6);
      h2 {
         color: $white;
         font-size: 1.25rem;
         font-weight: 600;
         line-height: 1.875rem;
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 0.875rem;
         }
      }
   }
   .partner_images {
      @include row_w;
      @include jc(center);
      @include ai(center);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
         @include flexbox;
         @include flex-direction(column);
         @include jc(center);
         @include ai(center);
      }
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
         gap: 20px;
      }
      img {
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include sv("margin-right", 0);
            @include sv("margin-bottom", 3);
            // width: 160px;
            // height: 70px;
         }
         @include sv("margin-right", 6);
         &#arc {
            max-width: 162px;
            max-height: 95px;
         }
         &#anu {
            max-width: 169px;
            max-height: 54px;
         }
         &#uom {
            max-width: 252px;
            max-height: 84px;
         }
         &#uoq {
            max-width: 205px;
            max-height: 54px;
         }
         &#wsu {
            max-width: 165px;
            max-height: 65px;
         }
      }
   }
   .acknowledgement {
      @include width(contentwidth);
      color: $white;
      text-align: center;
      @include sv("padding-top", 4);
      font-size: 1.125rem;
      p {
         @include sv("margin-bottom", 4);
      }
   }
}
