// connections styles
.connections {
  #map {
    background-color: #3e6a70;
    min-height: 100vh;
    &.slide_open {
      margin-left: 30vw;
    }
  }
  .filter_map {
    position: absolute;
    bottom: 10vh;
    @include flexbox;
    @include jc(space-around);
    @include ai(flex-end);
    width: 100%;
    z-index: 999;
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      @include flex-direction(column);
      @include jc(space-around);
      @include ai(center);
      bottom: 4vh;
    }
    @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
      @include flex-direction(column);
      @include jc(space-around);
      @include ai(center);
      bottom: 4vh;
    }
    .search {
      @include flexbox;
      @include ai(center);
      span.icon-lines {
        font-size: 1.1rem;
        @include sv-s("padding", 1.3, 1.1);
        border: 1px solid $olive;
        border-radius: 50%;
        @include sv("margin-right", 1.3);
      }
      p {
        text-transform: uppercase;
        color: $olive;
        font-size: 1.1rem;
        line-height: 1.5rem;
        font-weight: 600;
      }
      &:hover {
        cursor: pointer;
        span {
          display: inline-block;
          width: 47px;
          height: 45px;
          content: url(../images/filled-menu-button.svg);
          border: 0px;
          @include sv-s("padding", 0);
        }
      }
    }
    .research_list {
      > * {
        margin: 0 auto;
      }
      text-align: center;
      p {
        width: 70%;
        font-size: 1.125rem;
        line-height: 1.55rem;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          display: none;
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          display: none;
        }
      }
      ul {
        width: 100%;
        list-style: none;
        @include row_w;
        gap: 20px;
        @include jc(center);
        @include sv-tb("padding", 4);
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          gap: 20px;
          @include ai(center);
          @include jc(center);
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          gap: 40px;
          @include ai(center);
          @include jc(center);
        }
        li {
          @include sv-s("padding", 1, 2);
          border-radius: 50px;
          cursor: pointer;
          &.all {
            &:hover,
            &.active {
              background-color: $black_coedl;
              color: $white;
            }
            border: 2px solid $black_coedl;
          }
          &.research {
            &:hover,
            &.active {
              background-color: $steel;
              color: $white;
            }
            border: 2px solid $steel;
          }
          &.languages {
            &:hover,
            &.active {
              background-color: $red;
              color: $white;
            }
            border: 2px solid $red;
          }
          &.people {
            &:hover,
            &.active {
              background-color: $olive;
              color: $white;
            }
            border: 2px solid $olive;
          }
          &.outreach {
            &:hover,
            &.active {
              background-color: $orange;
              color: $white;
            }
            border: 2px solid $orange;
          }
        }
      }
    }
    .switch-button-area {
      .switch {
        cursor: pointer;
      }
    }
  }
  #filter_list {
    transition: right 1s ease-in;
    display: none;
    position: absolute;
    top: 0;
    height: 100vh;
    background-color: $white;
    z-index: 99999;
    .search_container {
      @include flexbox;
      .filter_wrapper {
        @include flexbox;
        max-height: 100vh;

        .categories {
          @include sv-s("padding", 11.2, 3.2, 0, 4.8);
          @include flexbox;
          @include flex-direction(column);
          @include jc(space-between);
          height: 100%;
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include sv-s("padding", 6.2, 2.2, 0, 2.8);
          }
          .category_wrapper {
            h3 {
              @include sv("margin-bottom", 3.2);
              color: $black_coedl;
              font-size: 1.45rem;
              line-height: 2.215rem;
              font-weight: 600;
            }
            ul {
              list-style: none;
              li {
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 600;
                border-bottom: 1px solid $grey_bg_light;
                @include sv-tb("padding", 1.6);
                @include icon(right, $icon-chevron-right);
                @include flexbox;
                @include ai(center);
                @include jc(space-between);
                a {
                  text-decoration: none;
                  color: $black_coedl;
                }
                &:after {
                  @include sv("margin-left", 1);
                  transform: rotate(90deg);
                  font-size: 1.5rem;
                }
                &:hover {
                  cursor: pointer;
                }
                &.research {
                  &.active,
                  &:hover {
                    &:after {
                      color: $white;
                      background-color: $steel;
                      border-radius: 50%;
                      transform: rotate(0deg);
                    }
                  }
                  &:after {
                    color: $steel;
                  }
                }
                &.languages {
                  &.active,
                  &:hover {
                    &:after {
                      color: $white;
                      background-color: $red;
                      border-radius: 50%;
                      transform: rotate(0deg);
                    }
                  }
                  &:after {
                    color: $red;
                  }
                }
                &.people {
                  &.active,
                  &:hover {
                    &:after {
                      color: $white;
                      background-color: $olive;
                      border-radius: 50%;
                      transform: rotate(0deg);
                    }
                  }
                  &:after {
                    color: $olive;
                  }
                }
                &.outreach {
                  &.active,
                  &:hover {
                    &:after {
                      color: $white;
                      background-color: $orange;
                      border-radius: 50%;
                      transform: rotate(0deg);
                    }
                  }
                  &:after {
                    color: $orange;
                  }
                }
              }
            }
          }
          .close_section {
            @include sv("margin-bottom", 7);
            a {
              text-transform: uppercase;
              color: $olive;
              line-height: 1.5rem;
              font-weight: 600;
              @include flexbox;
              @include ai(center);
              span {
                @include sv("padding-right", 1.2);
                &:before {
                  font-size: 2rem;
                }
              }

              &:hover {
                cursor: pointer;
                span {
                  &:before {
                    background-color: $olive;
                    border-radius: 50%;
                    color: $white;
                  }
                }
              }
            }
          }
        }
        .results {
          height: 100vh;
          @include sv-s("padding", 11.2, 3.2, 0, 4.8);
          background-color: $steel;
          color: $white;
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include sv-s("padding", 6.2, 2.2, 0, 2.8);
          }
          @media screen and (max-width: 390px) {
            @include sv-s("padding", 6.2, 1.2, 0, 2);
          }

          .input_section {
            @include sv("margin-bottom", 3.2);
            border: 1px solid transparent;
            border-radius: 50px;
            background-color: rgba(255, 255, 255, 0.3);
            @include flexbox;

            .search {
              border: none;
              background: transparent;
              margin: 0;
              padding: 7px 8px;
              font-size: 14px;
              color: inherit;
              border: 1px solid transparent;
              border-radius: inherit;

              border-radius: 50px;
              color: $white;
              &::placeholder {
                color: $white;
              }
            }
            button[type="submit"] {
              text-indent: -999px;
              overflow: hidden;
              width: 40px;
              height: 40px;
              padding: 0;
              margin: 0;
              border: 1px solid transparent;
              border-radius: inherit;
              background: transparent url("../images/search.svg") no-repeat center;
              cursor: pointer;
              opacity: 0.7;
            }
          }

          h3 {
            @include sv("margin-bottom", 1.6);
            font-size: 1.5rem;
            line-height: 2.125rem;
          }
          .list_results_generated {
            height: 85vh;
            @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
              height: 76vh;
            }
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              height: 76vh;
            }
            overflow: scroll;
            @include sv("padding-bottom", 15);
            ul {
              list-style: none;
              li {
                @include sv("margin-bottom", 1);
                cursor: pointer;
                max-width: 200px;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      .searched_results {
        display: none;
        .output_results {
          overflow: scroll;
          scroll-behavior: smooth;
          max-height: 100vh;
          &.research {
            background-color: $steel;
          }
          &.languages {
            background-color: $red;
          }
          &.outreach {
            background-color: $orange;
          }
          &.people {
            background-color: $olive;
          }
          &.node {
            background-color: $black_coedl;
          }

          max-width: 30vw;
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            max-width: 100vw;
          }
          @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
            max-width: 50vw;
          }
          .container {
            background-color: $white;
            @include sv("padding", 4);
            @include sv("margin-left", 0.8);
            .close {
              text-transform: uppercase;
              display: inline-flex;
              width: 100%;
              @include jc(flex-end);
              color: $olive;
              @include flexbox;
              @include ai(center);
              line-height: 1.5rem;
              font-weight: 600;
              cursor: pointer;
              .icon-cross {
                font-size: 2rem;
                @include sv("padding-left", 1.6);
              }
              &:hover {
                cursor: pointer;
                span {
                  &:before {
                    background-color: $olive;
                    border-radius: 50%;
                    color: $white;
                  }
                }
              }
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                @include sv("margin-top", 3);
              }
            }
            .results_container {
              @include sv("padding-top", 4);
              .results_title {
                @include flexbox;
                .image {
                  img {
                    width: 132px;
                    height: 132px;
                    @include sv("margin-right", 3.2);
                    object-fit: cover;
                  }
                  &.people {
                    img {
                      border-radius: 50%;
                    }
                  }
                  &.node {
                    img {
                      object-fit: contain;
                    }
                  }
                }
                h4.categories {
                  text-transform: capitalize;
                  &.red {
                    color: $red;
                  }
                  &.orange {
                    color: $orange;
                  }
                  &.olive {
                    color: $olive;
                  }
                  &.steel {
                    color: $steel;
                  }
                }
                h1.title {
                  @include sv-s("margin", 0, 0, 1.6, 0);
                  &.red {
                    color: $red;
                  }
                  &.orange {
                    color: $orange;
                  }
                  &.olive {
                    color: $olive;
                  }
                  &.steel {
                    color: $steel;
                  }
                }
                h2.location {
                  color: $light-black;
                  @include sv-s("margin", 1.6, 0, 3.2, 0);
                  font-weight: 400;
                }
              }
              .result_information {
                p {
                  color: $light-black;
                  line-height: 1.5rem;
                  font-weight: 400;
                }
                .archieve {
                  @include sv-tb("margin", 3.2);
                  h3 {
                    font-size: 1.25rem;
                    font-weight: 600;
                    line-height: 1.875rem;
                    @include sv("margin-bottom", 1.6);
                  }
                  .numbers_of_archieve {
                    @include sv("margin-top", 1.6);
                    a {
                      border-radius: 50%;
                      @include sv-s("padding", 1, 1.6);
                      text-decoration: none;
                      @include sv("margin-right", 1.2);
                      &.languages {
                        border: 1px solid $red;
                        color: $red;
                      }
                      &.people {
                        border: 1px solid $olive;
                        color: $olive;
                      }
                      &.research {
                        border: 1px solid $steel;
                        color: $steel;
                      }
                      &.outreach {
                        border: 1px solid $orange;
                        color: $orange;
                      }
                    }
                  }
                }
                .links {
                  @include sv-tb("margin", 3.7);
                  h3 {
                    font-size: 1.25rem;
                    font-weight: 600;
                    line-height: 1.875rem;
                  }
                  .links_container {
                    @include flexbox;
                    @include sv("margin-top", 1.6);
                    > div {
                      &.youtube {
                        a {
                          @include sv-s("padding", 1.35, 1.2);
                        }
                      }

                      a {
                        text-decoration: none;
                        @include sv("margin-right", 1.2);
                        border-radius: 50%;
                        @include sv-s("padding", 1.2);
                        @include flexbox;
                        span {
                          font-size: 1rem;
                          &.icon-youtube {
                            font-size: 0.8rem;
                          }
                        }

                        &.research {
                          border: 1px solid $steel;
                          span {
                            &::before {
                              color: $steel;
                            }
                          }
                        }
                        &.outreach {
                          border: 1px solid $orange;
                          span {
                            &::before {
                              color: $orange;
                            }
                          }
                        }
                        &.languages {
                          border: 1px solid $red;
                          span {
                            &::before {
                              color: $red;
                            }
                          }
                        }
                        &.people {
                          border: 1px solid $olive;
                          span {
                            &::before {
                              color: $olive;
                            }
                          }
                        }
                        &.node {
                          border: 1px solid $black_coedl;
                          span {
                            &::before {
                              color: $black_coedl;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .show_related_stories {
            .related_stories_title {
              @include flexbox;
              @include jc(space-between);
              @include sv("margin-bottom", 2.8);
              @include sv-s("padding", 4, 3.2, 0, 4);
              color: $white;
              font-size: 1.875rem;
              font-weight: 600;
              line-height: 2.5rem;
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                font-size: 1.25rem;
                font-weight: 600;
                line-height: 1.5rem;
              }
              @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 1.75rem;
              }
              span.icons {
                > span {
                  font-size: 2.5rem;
                  cursor: pointer;
                  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                    font-size: 1.5rem;
                  }
                  @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                    font-size: 2rem;
                  }
                  &:before {
                    color: $white;
                  }
                }
              }
            }
            .list_stories {
              @include sv("padding-left", 4);
              overflow: hidden;
              .slick-track {
                @include flexbox;
              }
              .story {
                img {
                  //max-width: 250px;
                  max-height: 160px;
                  width: 230px;
                  height: 160px;
                  object-fit: cover;
                  @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                    width: 170px;
                  }
                }

                display: inline-block;
                @include sv("margin-right", 2);
                h3 {
                  color: $white;
                  @include sv("margin-bottom", 2);
                  font-size: 1.25rem;
                  font-weight: 600;
                  line-height: 1.875rem;
                }
              }
              .slick-dots {
                text-align: center;
                @include flexbox;
                @include jc(center);
                @include sv("padding-right", 2);
                list-style: none;
                li {
                  button {
                    font-size: 0;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    border: none;
                    margin: 5px;
                    opacity: 0.5;
                  }
                  &.slick-active {
                    button {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
          .next_category {
            .related_category_item {
              @include sv-s("padding", 0, 3.2, 0, 4.9);
              color: $white;
              ul {
                list-style: none;
                li {
                  color: $white;
                  cursor: pointer;
                  font-weight: 600;
                  font-size: 1.5rem;
                  line-height: 2.125rem;
                  @include sv("padding-top", 2.8);
                  @include sv("padding-bottom", 1.6);
                  &:last-child {
                    @include sv("padding-bottom", 4);
                  }
                  &:not(:first-of-type) {
                    border-top: 1px solid $white;
                  }
                }
                span {
                  display: block;
                  font-weight: 400;
                  font-size: 1rem;
                  line-height: 1.5rem;
                  @include sv("padding-bottom", 1.6);
                }
              }
              h2.title {
                @include sv("padding-top", 4);
                font-size: 1.875rem;
                font-weight: 600;
                line-height: 2.5rem;
              }
              .lists {
                @include sv("margin-top", 2.8);
                h3 {
                  font-size: 1.5rem;
                  font-weight: 600;
                  line-height: 2.215rem;
                  @include icon(right, $icon-chevron-right);
                  @include flexbox;
                  @include jc(space-between);
                }
                p {
                  line-height: 1.5rem;
                  font-weight: 400;
                  border-bottom: 0.5px solid $white;
                }
              }
              &#research {
                background-color: $steel;
              }
              &#people {
                background-color: $olive;
              }
              &#outreach {
                background-color: $orange;
              }
              &#languages {
                background-color: $red;
              }
              &#node {
                background-color: $black_coedl;
              }
            }
          }
        }
      }
    }
  }
}
