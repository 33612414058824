// Website container

 .switch-button-area {
		max-width: 100%;
		font-size: rem(18);
    .switch {
      position: relative;
      background: $white;
     
		width: 100%;
      @include row_nw;
		@include jc(space-between);
      border-radius: 50px;
      // height: 50px;
      overflow: hidden;
      padding: 2px;
      color: $olive;

      >span {
        display: inline-flex;
			@include sv-s(padding, 0.6,2);
        flex: 0 0 50%;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        z-index: 2;
      }

      &:after {
        position: absolute;
        top: 1px;
        right: auto;
        left: 1px;
        border-radius: 50px;
        content: "";
        width: 50%;
        height: calc(100% - 2px);
        background: $olive;
		  transition: all 0.5s ease-in-out;
        color: $olive;
      }
    }

    input[type='checkbox'] {
      display: none;

      &:checked~.switch {
        >.map{
			transition: color 0.2s ease-in-out;
          color: $white;
        }
      }

      &:not(:checked)~.switch {
        &:after {
          left: 50%;
        }

        >.list {
			transition: color 0.2s ease-in-out;
          color: $white;
        }
      }
    }

  }