//@include width(full-width)
@mixin width($type: none) {
  border: 0px;
  @if $type == sitewidth {
    max-width: 100%;
    margin: auto;
    //IE FIXES
    width: 100%;
  } @else if $type == fullwidth {
    max-width: 1540px;
    margin: auto;
    width: 100%;
  } @else if $type == contentwidth {
    max-width: 1040px;
    margin: auto;
    width: 100%;
  } @else if $type == bannerwidth {
    max-width: 1540px;
    margin: auto;
    width: 100%;
  } @else if $type == bordertextwidth {
    max-width: 1482px;
    margin: auto;
    width: 100%;
  }
}
