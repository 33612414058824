// stories styles
.stories_content {
   background-color: $red;
   @include sv("padding-top", 12);

   .contents {
      width: inherit;
      color: $white;
      h1 {
         text-align: center;
         font-size: 9.375rem;
         line-height: 8rem;
         font-weight: 300;
         @include sv("margin-bottom", 8);
         @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
            font-size: 5.875rem;
            line-height: 4rem;
            font-weight: 150;
         }
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            font-size: 3.5rem;
            line-height: 3rem;
            font-weight: 300;
         }
      }
      .borders {
         position: relative;
         margin-left: 37%;
         margin-right: 37%;

         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            margin-left: 15%;
            margin-right: 15%;
         }
         @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
            margin-left: 15%;
            margin-right: 15%;
         }
         @media screen and (max-width: 1366px) and (min-width: 1000px) {
            margin-left: 26%;
            margin-right: 26%;
         }
         @media screen and (max-width: 1480px) and (min-width: 1367px) {
            margin-left: 29%;
            margin-right: 29%;
         }
         @include sv-s("padding", 0.3, 4);
         border-left: 3px solid $white;
         border-right: 3px solid $white;
         text-align: center;
         @include borders($white, 3px);
         .text {
            position: relative;
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-weight: 400;
            max-width: 500px;
            margin: 0 auto;
         }
      }
      .stories_list {
         @include width(fullwidth);
         @include row_nw;
         @include jc(space-around);
         @include sv("margin-top", 11);
         @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include flex-direction(column);
            @include ai(center);
         }
         @media screen and (min-width: 1140px) and (max-width: 1640px) {
            @include sv-lr("padding", 4);
         }
         @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
         }
         .images {
            position: sticky;
            top: 10%;
            order: 2;
            max-width: 680px;
            max-height: 513px;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
               order: 1;
               position: relative;
               width: 80%;
               max-height: 400px;
            }
            @media screen and (max-width: 1279px) and (min-width: $mobileBreakpoint) {
               width: 60%;
               max-height: 300px;
            }
            img {
               width: 100%;
               height: 100%;
               opacity: 0.5;
               object-fit: scale-down;
            }
         }
         .stories {
            @include sv("margin-top", 20);
            list-style: none;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
               order: 2;
               @include sv("margin-top", 8);
            }
            a {
               color: $white;
               text-decoration: none;
               li {
                  cursor: pointer;
                  @include flexbox;
                  font-size: 3rem;
                  line-height: 3.625rem;
                  font-weight: 600;
                  // @include icon(left,$icon-arrow-right);
                  @include sv("margin-bottom", 11);
                  &:hover {
                     span {
                        &:before {
                           @include sv("margin-right", 3.2);
                           content: url(../images/arrow-white-2.svg);
                           width: 100%;
                           height: 100%;
                           @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                              width: 2rem;
                              height: 2rem;
                              @include sv("margin-right", 2);
                           }
                           @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                              width: 2rem;
                              height: 2rem;
                              @include sv("margin-right", 2);
                           }
                        }
                     }
                  }
                  span {
                     @include sv-s("margin", 0.7, 3.2, 0, 0);
                     min-width: 48px;
                     min-height: 48px;
                     &:before {
                        color: $white;
                     }
                     @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                        min-width: 34px;
                        min-height: 34px;
                     }
                     @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                        min-width: 34px;
                        min-height: 34px;
                     }
                  }
                  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                     @include jc(flex-start);
                     @include ai(center);
                     font-size: 2rem;
                     line-height: 2.625rem;
                     font-weight: 600;
                     @include sv-lr("margin", 3);
                     @include sv("margin-bottom", 5);
                  }
                  @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
                     font-size: 2rem;
                     line-height: 2.625rem;
                     font-weight: 600;
                     @include sv-lr("margin", 3);
                     @include sv("margin-bottom", 8);
                  }
                  &:before {
                     // @include sv('margin-right',3.2);
                     // width: 48px;
                     // height: 48px;
                     // @media screen and (max-width:#{$mobileBreakpoint - 1}){
                     //   @include sv('margin-right',2);
                     // }
                  }
               }
            }
         }
      }
   }
   @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      min-height: 3000px;
   }
}
