// about styles
.about_contents {
  @include sv("padding-top", 10);
  .contents {
    width: 100%;
    @include sv("margin-top", 9);
    @media screen and (max-width:#{$mobileBreakpoint - 1}) {
      top: 10%;
    }
    .borders {
      position: relative;

      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include sv-s("padding", 3.7, 2);
        width: 90%;
      }

      @include sv-s("padding", 8, 8);
      border-left: 10px solid $olive;
      border-right: 10px solid $olive;

      @include borders($olive, 10px);
      .text {
        font-size: 3rem;
        line-height: 4rem;
        color: $steel;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          font-size: 1.8rem;
          line-height: 2.5rem;
        }
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          font-size: 2rem;
          line-height: 3rem;
        }
      }
      .slanted-line {
        position: absolute;
        bottom: 0%;
        right: 10%;
        width: 120px;
        height: 10px;
        background-color: $olive;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          right: 30%;
        }
        &:after {
          content: "";
          background-color: $olive;
          transform: rotate(30deg);
          width: 100px;
          height: 10px;
          position: absolute;
          right: -92px;
          bottom: -24px;
        }
      }
      @media screen and (max-width: 1620px) and (min-width: $mobileBreakpoint) {
        width: 80%;
      }
    }
    .image_column {
      @include sv("margin-top", 10);
      @include flexbox;
      @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
        flex-wrap: wrap;
      }
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        display: block;
        text-align: center;
      }
      .slider {
        max-width: 25%;
        text-align: center;
        @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
          max-width: 50%;
          @include sv("margin-top", 10);
        }
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          max-width: 100%;
        }
        img {
          width: 100%;
          max-height: 200px;
          object-fit: contain;
        }
      }
    }

    @keyframes scroll-x {
      from {
        transform: translateX(-400%);
      }
      to {
        transform: translateX(400%);
      }
    }
    @keyframes scroll-y {
      from {
        transform: translateY(0%);
      }
      to {
        transform: translateY(100%);
      }
    }
    .about_center {
      margin-top: 5%;
      @include row_nw;
      @include jc(space-between);
      @include ai(center);
      // margin-bottom: 10%;
      @include sv("margin-bottom", 50);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include sv("margin-bottom", 2);
      }
      @media screen and (max-width: 1280px) and (min-width: $mobileBreakpoint) {
        @include sv("margin-bottom", 20);
      }
      .title {
        flex: 0 0 50%;
        @include flexbox;
        @include ai(center);
        @include jc(flex-start);
        background-image: url(../images/about_background.svg);
        min-width: 549px;
        min-height: 549px;
        background-repeat: no-repeat;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          min-width: 349px;
          min-height: 349px;
          background-size: cover;
        }
        h1 {
          font-size: 4.5rem;
          line-height: 4.5rem;
          color: $steel;
          font-weight: 300;
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include sv-lr("padding", 2);
          }
          @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
            @include sv("padding-left", 5);
          }
        }
        @media screen and (max-width: 1280px) and (min-width: $mobileBreakpoint) {
          min-width: 450px;
          min-height: 450px;
          @include sv-lr("padding", 4);
          text-align: center;
        }
      }
      .description {
        flex: 0 0 50%;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.875rem;
        font-weight: 400;
        color: $light-black;
        p {
          @include sv("margin-bottom", 4);
        }
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          max-width: 100%;
          @include sv("padding", 2);
        }
        @media screen and (max-width: 1620px) and (min-width: $mobileBreakpoint) {
          max-width: 100%;
          @include sv("padding", 2);
        }
      }
      @media screen and (max-width: 820px) {
        @include flex-direction(column);
      }
      @media screen and (max-width: 1620px) and (min-width: 821px) {
        @include sv-tb("padding", 2);
        margin-top: 5%;
      }
    }
    .works {
      // position: relative;

      .image_slider_and_work {
        @include width(full-width);
        position: relative;

        .works_slider_images {
          position: absolute;
          margin: 0 auto;
          left: 0;
          right: 0;
          top: -33%;
          z-index: 99;
          @include flexbox;
          @include jc(center);
          @include ai(center);
          width: 1150px;
          margin: 0 auto;
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            width: 100%;
            height: auto;
          }
          @media screen and (max-width: 1200px) and (min-width: $mobileBreakpoint) {
            width: 100%;
            height: auto;
          }
          > span {
            font-size: 3.75rem;
            cursor: pointer;
            &:before {
              color: $olive;
            }
            &.icon-arrow-left {
              position: absolute;
              top: 50%;
              left: -10%;
              z-index: 99;
              @media screen and (max-width: 1450px) {
                display: none !important;
              }
            }
            &.icon-arrow-right {
              position: absolute;
              top: 50%;
              right: -10%;
              z-index: 99;
            }
            @media screen and (max-width: 1450px) {
              display: none !important;
            }
          }
          .slick-dots {
            position: absolute;
            bottom: 5%;
            @include flexbox;
            list-style: none;
            li {
              button {
                font-size: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: none;
                margin: 5px;
                opacity: 0.5;
              }
              &.slick-active {
                button {
                  opacity: 1;
                }
              }
            }
          }
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            position: relative;
          }
          @media screen and (max-width: 1280px) and (min-width: $mobileBreakpoint) {
            position: relative;
          }
        }
        .work_static_text {
          background-color: $steel;
          width: 100%;
          @include sv("padding-top", 42);
          @include sv("padding-bottom", 12);
          @media screen and (max-width:#{$mobileBreakpoint - 1}) {
            @include sv-tb("padding", 10);
            @include sv-lr("padding", 2);
            min-height: 100%;
          }
          @media screen and (max-width: 1280px) and (min-width: $mobileBreakpoint) {
            @include sv-tb("padding", 10);
            @include sv-lr("padding", 2);
            min-height: 100%;
          }
          .container {
            @include width(fullwidth);
            @include flexbox;
            @media screen and (max-width:#{$mobileBreakpoint - 1}) {
              @include flex-direction(column);
            }
            @media screen and (max-width: 1620px) and (min-width: $mobileBreakpoint) {
              @include sv-lr("padding", 5);
            }
            .title {
              flex: 0 0 50%;
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                //flex: 0 0 100%;
                @include flex-direction(column);
              }

              h1 {
                margin-top: 0;
                font-size: 4.5rem;
                line-height: 4.5rem;
                color: $white;
                font-weight: 300;
              }
            }
            .description {
              @media screen and (max-width:#{$mobileBreakpoint - 1}) {
                //@include sv('padding',2);
              }
              p {
                font-size: 1.25rem;
                line-height: 1.875rem;
                color: $white;
                font-weight: 400;
                @include sv("margin-bottom", 4);
              }
            }
          }
        }
      }
    }
    .selected_highlights {
      @include sv-tb("padding", 12);
      background-color: $orange;
      color: $white;
      @include row_w;
      @include jc(center);
      @media screen and (max-width:#{$mobileBreakpoint - 1}) {
        @include sv-s("padding", 10, 2);
      }
      .container {
        @include width(fullwidth);
        @include flexbox;
        @media screen and (max-width:#{$mobileBreakpoint - 1}) {
          @include flex-direction(column);
        }
        @media screen and (max-width: 1620px) and (min-width: $mobileBreakpoint) {
          @include sv-lr("padding", 5);
        }
        .title {
          flex: 0 0 50%;
          h1 {
            width: 50%;
            margin-top: 0;
            font-size: 4.5rem;
            line-height: 4.5rem;
            color: $white;
            font-weight: 300;
          }
        }
        .descriptions {
          flex: 0 0 50%;
          p {
            font-size: 1.25rem;
            line-height: 1.875rem;
            color: $white;
            font-weight: 400;
            @include sv("padding-bottom", 4);
          }
          a.inline_link {
            color: $steel;
          }
          a.link_highlight {
            @include flexbox;
            @include ai(center);
            text-transform: uppercase;
            font-size: 1.5rem;
            line-height: 2.25rem;

            color: $white;

            max-width: 350px;
            &:after {
              font-size: 3rem;
              display: inline-block;
            }
            &:not(:after) {
              width: 50%;
            }
          }
          span {
            font-size: 3rem;
            &:before {
              display: inline-block;
            }
            &.icon-arrow-right {
              &:before {
                color: $white;
                display: inline-block;
              }
            }
          }
        }
      }
    }
    > div {
      @include width(fullwidth);

      &.works,
      &.selected_highlights {
        @include width(sitewidth);
      }
    }
    @media screen and (max-width: #{$site-width + ($minmargin * 2)}px) and (min-width:$mobileBreakpoint) {
      @include sv("margin-top", 15);
    }
  }
  @media screen and (max-width:#{$mobileBreakpoint - 1}) {
  }
}
