// map_attributes styles
.marker-cluster-smallactive-pin-group,
.marker-cluster-mediumactive-pin-group,
.marker-cluster-largeactive-pin-group {
  background-color: grey;
  border-radius: 50%;
  width: 20px;
  color: $white;
}

.marker-cluster-smallresearch-group,
.marker-cluster-mediumresearch-group,
.marker-cluster-largeresearch-group {
  background-color: $steel;
  border-radius: 50%;
  width: 20px;
  color: $white;

  &.selected {
    width: 50px !important;
    height: 50px !important;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.4);
    > div {
      margin-left: 10px !important;
      margin-top: 10px !important;
    }
  }
}

.marker-cluster-smalllanguage-group,
.marker-cluster-mediumlanguage-group,
.marker-cluster-largelanguage-group {
  background-color: $red;
  border-radius: 50%;
  width: 20px;
  color: $white;

  &.selected {
    width: 50px !important;
    height: 50px !important;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.4);

    > div {
      margin-left: 10px !important;
      margin-top: 10px !important;
    }
  }
}

.marker-cluster-smallpeople-group,
.marker-cluster-mediumpeople-group,
.marker-cluster-largepeople-group {
  background-color: $olive;
  border-radius: 50%;
  width: 20px;
  color: $white;

  &.selected {
    width: 50px !important;
    height: 50px !important;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.4);

    > div {
      margin-left: 10px !important;
      margin-top: 10px !important;
    }
  }
}

.marker-cluster-smalloutreach-group,
.marker-cluster-mediumoutreach-group,
.marker-cluster-largeoutreach-group {
  background-color: $orange;
  border-radius: 50%;
  width: 20px;
  color: $white;

  &.selected {
    width: 50px !important;
    height: 50px !important;
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.4);

    > div {
      margin-left: 10px !important;
      margin-top: 10px !important;
    }
  }
}

.leaflet-top .leaflet-control-zoom {
  position: absolute;
  top: 100px;
  left: 80px;
}
