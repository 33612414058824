$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-arrow-left-filled-path1: "\e902";
$icon-arrow-left-filled-path2: "\e904";
$icon-chevron-left: "\e905";
$icon-arrow-right-filled-path1: "\e90c";
$icon-arrow-right-filled-path2: "\e911";
$icon-arrow-up-filled-path1: "\e914";
$icon-arrow-up-filled-path2: "\e915";
$icon-arrow-up-filled-path3: "\e916";
$icon-cross: "\e918";
$icon-globe: "\e919";
$icon-arrow-white-path1: "\e90d";
$icon-arrow-white-path2: "\e90e";
$icon-arrow-white-path3: "\e90f";
$icon-arrow-white-path4: "\e910";
$icon-lines: "\e903";
$icon-youtube: "\e906";
$icon-arrow-up: "\e912";
$icon-arrow-left: "\e909";
$icon-chevron-right: "\e91a";
$icon-arrow-right: "\e913";
$icon-facebook: "\e900";
$icon-twitter: "\e901";
$icon-document-duplicate: "\e907";
$icon-globe-alt: "\e908";
$icon-plus: "\e917";
$icon-search: "\e90a";
$icon-user: "\e90b";

